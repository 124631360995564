<template>
    <div>
       <table class="table">
            <tbody>
            <tr>
                <th>Quote</th>
                <th>Curbs to be Measured</th>
                <th>Assigned To</th>
                <th class="sortable" @click="sort('q.quote_date')">Date Required By</th>
                <th>Contact</th>
                <th>Measure Site Contact</th>
                <th class="sortable" @click="sort('qpmsl.measured')">Measured</th>
                <th>Date Measured</th>
            </tr>
            <tr v-for="request in dataState.state.data" v-bind:key="request.id">
                <td>
                    <router-link :to="`/quote/${request.quote_id}`">{{ request.quote_id }}</router-link>
                </td>
                <td :style="{ 'max-width': '200px', 'white-space': 'normal', 'word-wrap': 'break-word' }">
                   {{request.quote_curb_id}}
                </td>
                <td>
                    <span hidden>{{location}}</span>
                    <select class="form-control" v-model="request.assigned_measurer" @change="save(request.quote_pre_measure_sheet_id, request.assigned_measurer, request.measured, request.quote_curb_id)">
                        <option value="0">Not Set</option>
                        <option :class="{'bold': subOption.location == location}" v-for="subOption in subOptions" :value="subOption.Id" :key="subOption.order"> {{ subOption.name }}</option>
                     </select>
                </td>
                <td>
                    {{request.date_required}}
                </td>
                <td>
                    {{request.contact_contact_name}} {{request.contact_phone}}
                </td>
                <td>
                    {{request.measure_site_contact_name}} {{request.measure_site_phone}}
                    <br/>
                    {{request.address}} {{request.city}}, {{request.state}} {{request.zip}}
                </td>
                <td>
                    <label>
                        <input type='checkbox' :true-value='1' :false-value="0" v-model="request.measured" @change="save(request.quote_pre_measure_sheet_id, request.assigned_measurer, request.measured, request.quote_curb_id)"> Measured
                    </label>
                </td>
                <td>
                    {{request.date_measured}}
                </td>
            </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
import { dataStore } from '@/store/MeasureScheduler.store';
import { store } from '@/store/BuildScheduler.store';

export default {
    name: "Container",
    data() {
        return {
            dataState: dataStore,
            subOptions: [
                { name: 'Josh Eastman', location: 1, Id: 7766 },
                { name: 'Brian Renfro', location: 1, Id: 3632 },
                { name: 'Derek Schwartz', location: 1, Id: 25588 },
                { name: 'Jeremy(J.T.) Timm', location: 1, Id: 378 },
                { name: 'Paul Yeager', location: 1, Id: 12653 },
                { name: 'Josh Kerson', location: 2, Id: 38374 },
                { name: 'Michael Gerald', location: 3, Id: 12717 },
                { name: 'Jorge Nieto', location: 4, Id: 28281 },
                { name: 'Rafael Mora', location: 4, Id: 41040},
                { name: 'Justin Dela Cruz', location: 5, Id: 42893},
                { name: 'Phillip Pimentel', location: 5, Id: 45102},
            ],
            selectedOption: null,
        }
    },
    props: ['buildSchedule', 'user'],
    methods:{
        save(quote_pre_measure_sheet_id, assigned_measurer, measured, quote_curb_id){
            store.customAPI(`sch_measure/save_quote_premeasure`, {'params[quote_pre_measure_sheet_id]' : quote_pre_measure_sheet_id, 'params[assigned_measurer]' : assigned_measurer, 'params[measured]' : measured, 'params[quote_curb_id]' : quote_curb_id, 'params[date_measured]' : new Date().toISOString(), 'params[required_measurement]' : measured});
        },
        sort: function () {
            this.sortDirection = this.sortDirection === 'DESC' ? 'ASC' : 'DESC';
            dataStore.state.formFields.direction.val = this.sortDirection;

            let options={
                'params[direction]': this.dataState.state.formFields.direction.val,
            }
            store.customAPI('sch_measure/measure_scheduler_json', options).then( result => {this.dataState.state.data = result})
        },
    },
    watch:{
        location(){
            this.subOptions.sort((a, b) => {
                if (a.location === b.location) {
                    return 0;
                } else if (a.location === this.dataState.state.formFields.cdiLocationID) {
                    return -1;
                } else if (b.location === this.dataState.state.formFields.cdiLocationID) {
                    return 1;
                } else {
                    return a.location - b.location;
                }
            })
        }
    },
    computed: {
        location() {
            return this.dataState.state.formFields.cdiLocationID
        }
    },
}
</script>

<style scoped>
    @import '../../../assets/css/buildsch.css';
</style>